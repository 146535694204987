.container {
    display: flex;
    gap: var(--space-10);
    text-decoration: none;
    border-radius: var(--border-radius-5);
    transition: all var(--time-50);
    color: var(--color-gray-95);
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 
@media screen and (max-width: 800px) {
  .nav .details {
    display: none;
  }
}

@media screen and (max-width: 500px) {
    .nav .details {
      display: flex;
    }
  }

.container h3, .container p {
    padding: 0px;
    margin: 0px;
}

.container h3 {
    font-size: var(--font-size-details);
    color: var(--color-gray-95);
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.container p {
    font-size: var(--font-size-details);
    color: var(--color-gray-50);
}

/* Types */
/* Nav */
.nav {
    opacity: .5;
}

.nav:hover {
    opacity: 1;
}

/* Banner */
.banner {
    border-radius: var(--border-radius-5);
    color: var(--color-gray-95);
    background-color: rgba(0, 0, 0, 0.5);
    padding: var(--space-10);
    padding-right: var(--space-20);
    color: white;
}

.banner h3 {
    color: white;
}

.banner p {
    color: var(--color-gray-2);
}