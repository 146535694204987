.error {
    text-align: right;
    font-size: var(--font-size-details);
    padding: 3px 6px;
    border-radius: var(--border-radius-15);
    color: var(--color-red-50);
    background-color: var(--color-red-2);
    position: absolute;
    top: -15px;
    right: 17px;
  }

  .error p {
    margin: 0px;
  }

  .center {
    text-align: center;
    position: static;
  }