.container {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    position: relative;
}



.container img {
    border-radius: 100%;
    object-fit: cover;
    border: 4px solid var(--color-gray-50);
}

/* Type */

button.container:hover {
    opacity: .7;
}

/* Friend */

.friend {
    
    position: relative;
}

.friend .tooltip {
    pointer-events: none;
    z-index: var(--z-index-drawer);
    opacity: 0;
    transition: all var(--time-25);
    
    
}

.friend:hover img {
    transition: all var(--time-25);
    cursor: pointer;
    transform: translate(0, 0);
}

.friend:hover img {
    transform: translate(0, -5px);
    transition: all var(--time-25);
}

.friend:hover .tooltip {
    transition: all var(--time-25);
    opacity: 1;
}



/* Size */
.tiny {
    height: 38px;
    width: 38px;  
    flex: 0 0 38px;
}

.tiny img {
    border: 3px solid var(--color-gray-50);
}

.small {
    height: 50px;
    width: 50px;  
    flex: 0 0 50px;
}

.medium {
    height: 75px;
    width: 75px;  
    flex: 0 0 75px;
}

.large {
    height: 300px;
    width: 300px;  
}

.large img {
    border: 6px solid var(--color-gray-50);
}

.loadingOverlay {
    height: 100%;
    width: 100%;
    background-color: var(--color-gray-50);
    color: white;
    font-weight: bold;
    text-align: center;
    position: absolute;
    left:0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }