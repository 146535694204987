.section h2 {
    font-weight: bold;
    font-size: 24px;
    margin: 0px;
    line-height: 1.1;
}

.section h3 {
    font-weight: normal;
    font-size: 22px;
    margin: 0px;
}

.container {
    max-width: var(--width-50);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-20);
    padding: var(--space-20);
}

.bannerTopMargin {
    margin-top: 0px;
}