.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.container label {
  font-size: var(--font-size-body);
  text-align: left;
  padding-left: var(--space-10);
  padding-bottom: var(--space-5);
}

.container input {
  border: none;
  height: var(--height-50);
  border-radius: var(--border-radius-75);
  padding: 0 15px;
  transition: var(--time-50) all;
  font-size: var(--font-size-body);
  color: var(--color-gray-95);
  flex: 1 1 auto;
  border: 1px solid var(--color-gray-15);
  background-color: white;
}

.container input:hover {
  border: 1px solid var(--color-gray-35);
}

.container input:focus,
.container input:focus-visible {
  outline: none;
  border: 1px solid var(--color-orange-50);
  box-shadow: 0px 0px 0px 3px var(--color-orange-2);
}

.containerButton {
  display: flex;
  gap: var(--space-5);
  align-items: flex-end;
}

.containerButton .container {
  flex: 1 0 auto;
}

/* Date */
input[type="date"] {
  cursor: pointer;
  flex: 1 1 auto;
  min-width: calc(100% - var(--space-10)); /* Safari */
}

/* Safari */
input[type="date"]::-webkit-date-and-time-value {
  text-align: left;
  padding-left: var(--space-10);
}

.icon {
  position: absolute;
  right: 15px;
  bottom: 0px;
  height: 40px;
  display: flex;
  align-items: center;
  fill: var(--color-gray-50);
  pointer-events: none;
}
