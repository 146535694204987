.logo {
    width: 200px;
}

.icon {
    width: 27px;
    display: none;
}

@media (max-width: 800px) {
    .logo {
        display: none;
    }

    .icon {
        display: block;
    }
}
