/*//////////////// Banner /////////////////////*/
.sectionBanner {
  min-height: 450px;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  background-color: var(--color-gray-15);
  margin-top: 75px;
}

.containerBanner {
  background-position: top;
  background-repeat: no-repeat;
  min-height: 450px;
  max-width: var(--width-50);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  color: white;
  background-size: 1200px;
  padding: var(--space-20);
}

.containerBanner h1 {
  font-size: 50px;
  line-height: 1.1;
}

.containerBanner p {
  font-size: 20px;
  line-height: 1.5;
  margin-top: 8px;
}

.containerBanner button {
  background-color: white;
  min-width: 130px;
  padding: 20px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  color: var(--color-gray-75);
  cursor: pointer;
  font-size: var(--font-size-body);
  font-weight: bold;
  fill: var(--color-orange-75);
  border: none;
  position: relative;
  gap: var(--space-10);
  text-transform: uppercase;
  text-decoration: none;
}

.containerBanner a:has(button) {
  text-decoration: none;
}

.containerBanner button svg {
  height: 20px;
  width: 20px;
}

.containerBanner button:hover {
  background-color: var(--color-orange-75);
  fill: white;
  color: white;
}

.leftBanner {
  flex: 1 1 55%;
}

.rightBanner {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.desktopBanner {
  display: block;
}

.mobileBanner {
  display: none;
}

@media (max-width: 600px) {
  .desktopBanner {
    display: none;
  }

  .mobileBanner {
    display: block;
    min-height: 350px;
  }

  .mobileBanner .containerBanner {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 350px;
    background-size: cover;
  }

  .mobileBanner .containerBanner h1,
  .mobileBanner .containerBanner p {
    text-shadow: 0px 2px 4px #323232a5;
    text-align: center;
  }
}

/*//////////////// Slides /////////////////////*/
.containerSlides {
  width: 99vw;
  display: flex;
  align-items: center;
  margin: 1vw;
  margin-right: 0px;
  gap: var(--space-10);
  overflow-x: scroll;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.containerSlides::-webkit-scrollbar {
  display: none;
}

.containerSlides button {
  position: absolute;
  top: auto;
  right: var(--space-20);
}

.containerSlides article {
  flex: 0 0 300px;
  height: 200px;
  border-radius: var(--border-radius-15);
  background-color: var(--color-gray-15);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  /* cursor: pointer; */
}

.containerSlides article img {
  position: absolute;
  left: 0px;
  top: 0px;
  filter: brightness(50%);
  transition: all var(--time-50);
}

.containerSlides article:hover img {
  filter: brightness(100%);
}

.containerSlides article h2 {
  position: absolute;
  left: auto;
  top: auto;
  color: white;

  filter: brightness(100%);
  transition: all var(--time-50);
}

.containerSlides article:hover h2 {
  transform: scale(1.2);
}

/*//////////////// Content /////////////////////*/
.section {
  width: 100%;
  flex: 1 1 100%;
}

.container {
  max-width: var(--width-50);
  margin: 0 auto;
  display: flex;
  gap: var(--space-20) var(--space-50);
  padding: var(--space-20);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.container img {
  max-width: 100%;
}

/* Image that display a part of the application */
.container img.display {
  border-radius: 1.5vw;
  border: 1px solid var(--color-gray-15);
}

/* Charity Peacock Image for Fundraising */
.container img.charity {
  max-width: 200px;
  margin-bottom: var(--space-10);
  margin-left: -20px;
}

.icons {
  width: 100%;
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-10);
  justify-content: start;
  margin-bottom: var(--space-10);
}

.icons img {
  width: 40px;
}

.leftPanel {
  flex: 1 1 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightPanel {
  flex: 0 0 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  text-align: left;
}

.onlyPanel {
  flex: 0 1 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

/*//////////////// Ribbon /////////////////////*/

.sectionRibbon {
  width: 100%;
  background-color: var(--color-gray-5);
}

.containerRibbon {
  max-width: var(--width-25);
  margin: 0 auto;
  padding: var(--space-20);
  text-align: center;
}
