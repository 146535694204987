.inputRadio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.inputRadio p {
    margin-bottom: 5px;
}

.marketingForm input[type=submit] {
    background-color: white;
    min-width: 100px;
    padding: 0 20px;
    height: var(--height-50);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-orange-75);
    border-radius: var(--border-radius-75);
    color: var(--color-orange-75);
    cursor: pointer;
    font-size: var(--font-size-body);
    font-weight: bold;
    fill: var(--color-orange-75);
    position: relative;
    outline: var(--color-orange-75);
    text-transform: uppercase;
    text-decoration: none; 
    background-color: var(--color-orange-75);
    color: white;
}

.marketingForm input[type=submit]:hover {
    background-color: white;
    color: var(--color-orange-75);
  }