/* Drawer */

.drawerContainer {
    width: 300px;
    height: 100vh;
    padding: var(--space-30);
    position: fixed;
    top: 73px;   
    background-color: white;
    z-index: 80;
    box-shadow: var(--shadow-15);
    display: flex;
    flex-direction: column;
    gap: 25px;
    display: flex;
    z-index: var(--z-index-drawer);
    transition: all 0.5s ease-in-out;
    opacity: .5;
}



.drawerContainer a {
    color: var(--color-gray-95);
}

/* Position */

.left {
    left: 0px;
    transform: translateX(-100%);
}

.right {
    right: 0px;
    transform: translateX(100%);
}

.open {
    display: flex;
    transform: translateX(0);
    opacity: 1;
}

/* Native PopOver Drawer */

.popover[popover] {
    width: 300px;
    height: 100vh;
    padding: var(--space-30);
    border: 0px;
    box-shadow: var(--shadow-15);

    position: fixed;
     
    inset: unset;
    transform: translateX(0);
   
    background-color: white;
    overflow: hidden;
    top: 73px;
}

.popover[popover] a {
    color: var(--color-gray-95);
}

.popover .container {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

/* Left Position */

.left.popover[popover] {
    left: 0px;
}

.left.popover:popover-open {
    animation: slideLeft  0.5s ease-in-out forwards;
    inset: unset;
    opacity: .5;
    top: 73px;
    left: 0px;
}

/* .left.\:popover-open */

@keyframes slideLeft {
    0% {
        opacity: .5;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Right Position */

.right.popover[popover] {
    right: 0px;
}

.right.popover:popover-open {
    animation: slideRight  0.5s ease-in-out forwards;
    inset: unset;
    opacity: .5;
    top: 73px;
    right: 0px;
}

/* .left.\:popover-open */

@keyframes slideRight {
    0% {
        opacity: .5;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
