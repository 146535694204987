.container {
    display: flex;
    flex-direction: column;
    gap: var(--space-10);
    flex: 1 0 200px;
}

  @media (max-width: 800px) {
    .container {
        flex: 1 0 50px;
    }
  }

  @media (max-width: 500px) {
    .container {
      flex: 1 0 200px;
    }
  }



.space {
    padding-top: var(--space-10);
}

.container h2 {
    display: flex;
    gap: var(--space-5);
    align-items: center;
}

.section {
    display: flex;
    flex-direction: column;
    gap: var(--space-10);
    padding: var(--space-5) 0 0 var(--space-10);
}

