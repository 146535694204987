:root {
  /* Colors */
  /* --color-example-hsl: hsl(0 0% 0% / 0.5);   */
  /* Gray */
  --color-gray-95: #262729;
  --color-gray-75: #343333;
  --color-gray-50: #808080;
  --color-gray-35: #a6a6a6;
  --color-gray-25: #bfbfbf;
  --color-gray-15: #d9d9d9;
  --color-gray-10: #e6e5e5;
  --color-gray-5: #ededed;
  --color-gray-3: #f5f5f4;
  --color-gray-2: #fafafa;

  /* Gray */
  --color-orange-2: #fddfdd;
  --color-orange-5: #f8b9b5;
  --color-orange-50: #f16c67;
  --color-orange-75: #ee453d;

  /* Red */
  --color-red-2: #fae6e6; /* error back */
  --color-red-50: #bc0006; /* error text */

  /* New User Colors */
  --color-shine-50: #ecdd28;
  --color-zest-50: #ffca0d;
  --color-golden-50: #f37021;
  --color-ruby-50: #ed313e;
  --color-azure-50: #1268b3;

  --color-blast-50: #d91770;
  --color-keen-50: #b53e98;
  --color-salmon-50: #f05379;
  --color-sky-50: #009edb;
  --color-field-50: #8dc73f;

  --color-morning-50: #ffdf40;
  --color-ice-50: #9bd4e1; /* #BCD9E0 */
  --color-paradise-50: #2f3192;
  --color-trail-50: #009345;
  --color-cinnamon-50: #c3996b;

  --border-radius-5: 7px;
  --border-radius-15: 10px; /* container radius */
  --border-radius-75: 20px;

  /* Height */
  --height-25: 12px; /* friend progress bar was 12 */
  --height-50: 40px; /* button, input tabs */
  --height-75: 60px; /* user progress bar */

  /* Spacing */
  --space-2: 2px; /* gap between small items */
  --space-5: 5px; /* gap between small items */
  --space-10: 10px; /* gap between small items */
  --space-20: 24px; /* inside container padding */
  --space-30: 30px; /* gap between medium items */
  --space-50: 50px; /* gap between main sections */

  /* Width */
  --width-25: 900px; /* profile template max width */
  --width-50: 1250px; /* main template max width */

  /* Z Index */
  --z-index-test: 10000;
  --z-index-modal: 9000;
  --z-index-message: 8000;
  --z-index-menu: 7000;
  --z-index-header: 5000;
  --z-index-drawer: 4000;
  --z-index-content: 3000;
  --z-index-footer: 1000;

  /* Shadow */
  --shadow-5: 0px 0px 5px 2px #00000008; /* small elements */
  --shadow-15: 0px 3px 2px #00000010, 0px 4px 6px #1540a32f; /* drawers */
  --shadow-25: 0px 1px 2px #00000010, 0px 0px 6px #1540a32f; /* main element and containers */

  /* Type Size */
  --font-size-header: 40px;
  --font-size-title: 16px;
  --font-size-body: 14px;
  --font-size-details: 12px;
  --font-size-tiny: 10px;

  /* Animation */
  --time-25: 0.3s; /* smaller elements */
  --time-50: 0.7s; /* transition */
  --time-75: 1s; /* Full page animation / Overlay progress bar pre bar */
  --time-100: 1.5s; /* Full page animation / Overlay progress bar */

  /* Media */
  /* @media (max-width: 800px) { */
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-size: var(--font-size-body);
  outline: none;
  font-family: "Inter", sans-serif;
  font-style: 500;
  color: #333;
  font-size: 16px;
  background-color: var(--color-gray-2);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-gray-2);
}

button {
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
  gap: var(--space-10);
}

label {
  font-size: var(--font-size-body);
}

/* Stops mobile browsers from zooming in on input fields */
input,
textarea {
  text-size-adjust: 100%;
}

input[type="date"] {
  font-family: "Inter", sans-serif;
}

p {
  font-size: var(--font-size-body);
}

ul,
ol,
li {
  font-size: var(--font-size-body);
}

a {
  color: var(--color-orange-75);
}

a:hover {
  text-decoration: none;
  color: var(--color-orange-50);
}

h1 {
  padding: 0px;
  margin: 0px;
  font-size: 1.5rem;
}

h2 {
  font-size: var(--font-size-title);
  padding: 0px;
  margin: 0px;
  font-weight: 500;
  margin-bottom: -5px;
}
