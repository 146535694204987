

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #ffffff5a;
    border-top-color: #fff;
    animation: spin 1.5s linear infinite;
}

.small {
    width: 20px;
    height: 20px;
    border-width: 2px;
    animation-duration: 1.5s;
}

.large {
    width: 100px;
    height: 100px;
    border-width: 5px;
    animation-duration: 2s;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
