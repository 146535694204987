.container {
    border-radius: var(--border-radius-5);
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: white; */

}

.container img {
    border-radius: var(--border-radius-5);
    height: 50px;
    width: 50px;
    object-fit: cover;
    border: 1.5px solid var(--color-gray-15);
    background-color: white;
}

.container.noimage {
    background-color: var(--color-gray-15);
}