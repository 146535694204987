[popover] {
    position: fixed;
    z-index: 2147483647;
    inset: 0;
    padding: 0.25em;
    width: fit-content;
    height: fit-content;
    border-width: initial;
    border-color: initial;
    border-image: initial;
    border-style: solid;
    background-color: canvas;
    color: canvastext;
    overflow: auto;
    margin: auto;
  }
  [popover]:not(.\:popover-open) {
    display: none;
  }
  [popover]:is(dialog[open]) {
    display: revert;
  }
  [anchor].\:popover-open {
    inset: auto;
  }
  @supports selector([popover]:popover-open) {
    [popover]:not(.\:popover-open, dialog[open]) {
      display: revert;
    }
    [anchor]:is(:popover-open) {
      inset: auto;
    }
  }
  @supports not (background-color: canvas) {
    [popover] {
      background-color: white;
      color: black;
    }
  }
  @supports (width: -moz-fit-content) {
    [popover] {
      width: -moz-fit-content;
      height: -moz-fit-content;
    }
  }
  @supports not (inset: 0) {
    [popover] {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }