 
  .tip {
    min-width: 40px;
    height: 20px;
    position: absolute;
    right: -19px;
    bottom: -35px;   
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-5);
    font-size: var(--font-size-details);
    width: max-content;
    padding: 0 5px;
  }

.tip::before,
.tip::after {
  content: "";
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  left: auto; 
}

.tip::after {
  z-index: 1;
}

/*////// Position ///////*/
.bottom {
  bottom: -25px; 
}

.bottom::after {
  /* border-bottom: 3px solid var(--color-gray-50); */
  margin-top: -2px;
  bottom: 100%;
}


.top {
  top: -25px;
}

.top::after {
  /* border-top: 3px solid var(--color-gray-50); */
  margin-bottom: -2px;
  top: 100%;
}

/*////// Type ///////*/
.friend {
    width: max-content;
    padding: 0 5px;
    position: absolute;
    bottom: -28px;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
  
  