.tiny {
    width: 15px;
    height: 15px;
}

.small {
    width: 20px;
    height: 20px;
}

.medium {
    width: 32px;
    height: 32px;
}

.large {
    width: 50px;
    height: 50px;
}

.display {
    width: 100px;
    height: 100px;
}

