.back {
    background-color: white;
    width: 100%;
    box-shadow: var(--shadow-25);
    position: fixed;
    top: 0px;
    z-index: var(--z-index-header);
}

.backFooter {
    position: static;
}

.container {
    /* max-width: var(--width-50); */
    margin: 0 auto;
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--space-20);
}

.userContainer {
    display: flex;
    align-items: center;
    gap: var(--space-10);
}

.userContainer p {
    color: var(--color-gray-50);
    font-size: var(--font-size-body);
}

.center {
    text-align: center;
    text-align: center;
    margin: 0;
    padding-bottom: var(--space-10);
}

.navContainer {
    display: flex;
    align-items: center;
    gap: var(--space-30);
}

.navContainer a {
    text-decoration: none;
    color: var(--color-gray-50);
    font-size: var(--font-size-body);
    transition: all var(--time-25);
}

.navContainer a:hover {
    color: var(--color-gray-95);
}

/*///////// Mobile 800 ////////////*/

.navMobileContainer {
    display: none;
    align-items: center;
    gap: var(--space-30);
}

@media (max-width: 800px) { 

    .hideNavContainer {
        display: none;
    }

    .navMobileContainer {
        display: flex;
    }
}

/*///////// Mobile 500 ////////////*/

.navMobileContainer500 {
    display: none;
    align-items: center;
    /* gap: var(--space-30); */
}

@media (max-width: 500px) { 

    .hideNavContainer500 {
        display: none;
    }

    .navMobileContainer500 {
        display: flex;
    }
}