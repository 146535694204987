.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
}

.container h1 {
    font-size: 10rem;
    color: var(--color-gray-25);
    margin: 0;
    line-height: .9;
}

.container h2{
    font-size: 3rem;
    font-weight: bold;
    color: var(--color-gray-75);
    margin: 0;
}
