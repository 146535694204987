/* All States */

.container {
    /* background-color: var(--color-gray-5); */
    width: 100%;  
    fill: var(--color-gray-35);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.transparent {
    background-color: transparent;
    fill: var(--color-gray-15);
    /* margin-left: -45px;   */
}

@media (max-width: 800px) {
    .transparent {
        margin-left: 0px; 
    }
  }

.transparent p {
    color: var(--color-gray-25);
}



.iconContainer {
    margin-bottom: var(--space-10);
    margin-bottom: 0;
}

.container p {
    margin: 0px;
}

/* Sizes */
/* Full Page */
.container.full {
    padding: var(--space-30);
    border-radius: var(--border-radius-15);
    color: var(--color-gray-95);
    margin-left: -10px;
}

/* Nav */
.container.nav {
    background-color: transparent;
    padding: var(--space-10);
    border-radius: var(--border-radius-5);
    flex-direction: row;
    fill: var(--color-gray-15);
    margin-left: -10px;
    gap: var(--space-5);  
}

.container.nav p {
    padding: 0px var(--space-5);
    text-align: left;
    max-inline-size: 30ch;
    text-wrap: balance;
    color: var(--color-gray-25);
}

/* Hide container.nav betwee 500 and 800px */
@media (min-width: 500px) and (max-width: 800px) {
    .container.nav {
        display: none;
    }
}

/* Common */
.container.medium {
    padding: var(--space-20);
    border-radius: var(--border-radius-15);
    color: var(--color-gray-50);
}