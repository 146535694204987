.details {
  font-size: var(--font-size-details);
  color: var(--color-gray-50);
  text-align: left;
  padding-top: 0px;
  margin: 0;
  padding: 0;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.bold {
  font-size: var(--font-size-details);
  color: var(--color-gray-95);
  font-weight: 600;
}

@media (max-width: 900px) {
  .details {
    display: none;
  }
}
