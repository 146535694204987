.button {
  background-color: white;
  min-width: 100px;
  padding: 0 20px;
  height: var(--height-50);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-orange-75);
  border-radius: var(--border-radius-75);
  color: var(--color-orange-75);
  cursor: pointer;
  font-size: var(--font-size-body);
  font-weight: bold;
  fill: var(--color-orange-75);
  position: relative;
  outline: var(--color-orange-75);
  text-transform: uppercase;
  text-decoration: none;
}

.button:hover {
  background-color: var(--color-orange-75);
  fill: white;
  color: white;
}

.icon {
  border: none;
}

.banner {
  background-color: rgba(0, 0, 0, 0.7);
  fill: white;
  opacity: 0.7;
}

.banner:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
  fill: white;
}

a:has(.button) {
  text-decoration: none;
}

/* Types */
.primary {
  background-color: var(--color-orange-75);
  color: white;
}

.primary:hover {
  background-color: white;
  color: var(--color-orange-75);
}

.cancel {
  background-color: var(--color-gray-5);
  color: var(--color-gray-50);
  border: none;
}

.cancel a {
  text-decoration: none;
  color: var(--color-gray-50);
}

.cancel:hover {
  background-color: var(--color-gray-10);
  color: var(--color-gray-50);
  border: none;
}

.create {
  fill: var(--color-green-50);
}

.create:hover {
  background-color: var(--color-green-50);
  border-color: var(--color-green-50);
}

.test {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: transparent;
  color: transparent;
  border: none;
  right: 0;
  top: 0;
  z-index: var(--z-index-test);
  padding: 0;
}

.test:hover {
  background-color: transparent;
  color: transparent;
  border: none;
}

input.primary[type="file"] {
  background-color: transparent;
  color: transparent;
  border: none;

  width: 100%;
  position: relative;
}

input.primary[type="file"]::file-selector-button {
  background-color: white;
  min-width: 100px;
  height: var(--height-50);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--color-orange-75);
  border-radius: var(--border-radius-75);
  color: var(--color-orange-75);
  cursor: pointer;
  font-size: var(--font-size-body);
  font-weight: bold;
  fill: var(--color-orange-75);
  position: relative;
  outline: var(--color-orange-75);
  text-transform: uppercase;
  text-decoration: none;

  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

input.primary[type="file"]::file-selector-button:hover {
  background-color: var(--color-orange-75);
  color: white;
}

input.primary[type="file"]::file-selector {
  display: none;
}

/* Size */
.small {
  min-width: 35px;
  width: 35px;
  height: 35px;
  padding: 7px;
}

.tiny {
  min-width: 25px;
  width: 25px;
  height: 25px;
  padding: 0px;
}

/* State */
/* Loading */
.formContainer {
  position: relative;
}
.button.loading,
input.loading[type="file"]::file-selector-button {
  background-color: var(--color-gray-50) !important;
  color: white;
  border: none;
  cursor: wait;
}

.button.loading:hover,
input.loading[type="file"]::file-selector-button:hover {
  background-color: var(--color-gray-50) !important;
  color: white;
  border: none;
}

button.loading .icon,
input.loading[type="file"]::file-selector-button .icon {
  fill: white;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-left: var(--space-5);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingOverlay {
  height: 100%;
  width: 100%;
  background-color: var(--color-gray-50);
  color: white;
  font-weight: bold;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-75);
  fill: white;
}
