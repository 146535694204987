.details {
    position: absolute;
    right: 17px;
    font-size: var(--font-size-details);
    color: var(--color-gray-35);
}

.details p {
    margin: 0px;
}

.top {
    top: 25px;
    color: var(--color-gray-50);
}

.bottom {
    bottom: -30px;
}